import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import Outro from '../components/rows/outro'
import TwoCol from '../components/rows/twocol'

import Banner from '../images/img/hero/business-case-hero.jpg'

export default function BusinessCase({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Benefits - Business case" />
        <Hero 
            title="Business Case"
            banner={Banner}
        />
        {data.allStrapiBusinessCase.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
                <Intro key={node.business_intro.id}
                    title={node.business_intro.title}
                    subtitle={node.business_intro.subtitle}
                    description={node.business_intro.description}
                />
                <TwoCol
                    checkmark
                    key={node.business_twocol.id}
                    column1={node.business_twocol.description_1}
                    column2={node.business_twocol.description_2}
                />
                
                <Outro key={node.business_outro.id}
                    subtitle={node.business_outro.subtitle}
                    title={node.business_outro.title}
                    link={node.business_outro.button_title}
                    href={node.business_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query BusinessCase {
    allStrapiBusinessCase {
      edges {
        node {
          business_intro {
            id
            subtitle
            title
            description
          }
          business_twocol {
            id
            description_1
            description_2
          }
          business_outro {
            id
            subtitle
            title
            button_link
            button_title
          }
        }
      }
    }
  }
  
`